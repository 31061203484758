import type { ComponentType } from "react"
import { addPropertyControls, ControlType, RenderTarget } from "framer"

import { useCallback, useEffect, useMemo, useState } from "react"
import { setLastLesson as sll } from "https://framer.com/m/progress-OA2C.js@4EVUJY1LUz2tODtJw50y"
import { useAuth } from "https://framer.com/m/hooks-cRhB.js@T5LBfnlpkoCbxrpkCkRa"

import Placeholder from "https://framer.com/m/Placeholder-hsKY.js"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function SetLastLesson(props) {
    /**
     * Properties
     */

    const { courseTitle, lessonTitle, path } = props
    /**
     * Checks
     */
    const isCanvas = RenderTarget.current() === RenderTarget.canvas
    const { valid, message } = validateProps(props)

    const { user, isLoggedIn, loading } = useAuth()

    console.log({ user, isLoggedIn, loading })

    const dispatchEvent = useCallback(async () => {
        if (!isCanvas) {
            await sll({ userId: user.uid, ...props })
        } else {
            console.log("Evento de salvar ultima aula disparado!")
        }
    }, [courseTitle, lessonTitle, isLoggedIn])

    useEffect(() => {
        dispatchEvent()
    }, [courseTitle, lessonTitle])

    if (isCanvas && !valid) {
        return (
            <Placeholder title={"Faltou algum paramêtro"} subtitle={message} />
        )
    }

    return <></>
}

/**
 * Validate Props
 */
function validateProps(props) {
    const { courseTitle, lessonTitle, path } = props
    if (courseTitle && lessonTitle && path) {
        return { valid: true }
    } else {
        let missingField = ""
        if (!courseTitle) missingField = "courseTitle"
        else if (!lessonTitle) missingField = "lessonTitle"
        else missingField = "path"
        return {
            valid: false,
            message: `Missing required field: ${missingField}`,
        }
    }
}

addPropertyControls(SetLastLesson, {
    courseTitle: {
        title: "Course Title",
        type: ControlType.String,
    },
    lessonTitle: {
        title: "Lesson Title",
        type: ControlType.String,
    },
    path: {
        title: "Path to Lesson",
        type: ControlType.String,
    },
})
